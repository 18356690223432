.container.index {
    margin-top: 5rem;
}

.widget--haendlersearch {
    margin: 100px 60px;
}

body.fr,
body.it {
    section.header .container h1 {
        font-size: 32px;
    }
}

section.header .container {
    padding: 200px ​15px 10px 15p;
    @include media-breakpoint-up(md) {
        padding: 225px 15px 50px 15px;
    }
    color: white;
    h1 {
        font-size: 38px;
        text-shadow: rgba(0, 0, 0, 0.4) 0 0 10px;
    }
    img {
        max-width: 500px;
        display: block;
        margin: 30px 0;
    }
    a.btn {
        width: 230px;
        text-align: center;
        margin-right: 10px;
    }
}

section.intro {
    margin-top: 120px;
}

section.steps {
    background: linear-gradient(to right, #0d354a 0%, #062326 52%, #fff 52%, #fff 100%);
    color: white;
    margin-top: 50px;
    .content {
        padding: 40px 20px 0 15px;
        ol {
            padding-left: 17px;
            font-size: 18px;
            margin-top: 20px;
            li {
                margin-bottom: 5px;
            }
        }
    }
}

section.terms {
    margin-top: 70px;
    .row {
        margin-bottom: 20px;
    }
    .col-tabelle {
        margin-top: 75px;
    }
}

section.schadensfall {
    background: #f7f7f7;
    margin-top: 30px;
    .content {
        padding: 60px 15px;
        &.right {
            padding-top: 105px;
            a.btn {
                margin-top: 20px;
            }
        }
    }
}

section.last-blue {
    background: linear-gradient(to right, #fff 0%, #fff 45%, #0d354a 45%, #062326 100%);
    color: white;
    margin-top: 120px;
    .content {
        padding: 50px 20px 0 20px;
        a {
            margin-top: 5px;
        }
    }
}

section.closer {
    text-align: center;
    img {
        max-width: 152px;
        margin: 60px 0;
    }
}

@media (max-width: 1200px) {
    section.steps .content {
        ol {
            padding-left: 17px;
            font-size: 16px;
            margin-top: 20px;
        }
    }
}
@media (max-width: 990px) {
    .widget--haendlersearch {
        margin: 30px auto;
        max-width: 284px;
    }
}
@media (max-width: 768px) {
    section.intro {
        margin-top: 30px;
    }
    section.steps {
        background: none;
        margin-top: 30px;
        .content {
            background: linear-gradient(to right, #0d354a 0%, #062326 100%);
            padding: 40px 15px 30px 15px;
        }
        .order-1 {
            padding: 0;
        }
    }
    section.terms {
        margin-top: 40px;
        .content {
            margin-bottom: 30px;
        }
        .row:last-child {
            .content {
                margin-top: 20px;
            }
        }
        .col-tabelle {
            margin-top: 0px;
            margin-bottom: 30px;
        }
    }
    section.last-blue {
        margin-top: 70px;
        background: none;
        margin-top: 30px;
        .img {
            padding: 0;
        }
        .content {
            background: linear-gradient(to right, #0d354a 0%, #062326 100%);
            padding: 30px 15px 40px 15px;
            a.btn {
                display: table;
                margin: 0 auto;
            }
        }
    }
}

@media (max-width: 574px) {
    section.schadensfall .content {
        padding: 40px 15px;
        &.right {
            padding-top: 0px;
        }
    }
    body {
        footer.footer {
            position: relative;
            height: auto;
            ul {
                display: block;
                li {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}
