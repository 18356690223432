footer.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
  ul {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
          display: inline;
          a {
              padding: 10px 15px;
              color: $gray-900;
          }
      }
  }
}
