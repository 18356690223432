body {
    &.fr {
        section.header {
            background-image: url("/img/headers/header_fr_bg_city2.jpg");
        }
    }
    &.it {
        section.header {
            background-image: url("/img/headers/header_it_bg_city2.jpg");
        }
    }
    section.header {
        min-height: 500px;
        background: $black url("/img/headers/header_bg_city2.jpg") no-repeat;
        background-size: 1500px auto;
        background-position: center right 30%;
        position: relative;
        @include media-breakpoint-down(sm) {
            &::before {
                content: "";
                height: 100%;
                width: 100%;
                background: linear-gradient(to right, rgba($black, 0.7), rgba($black, 0.2));
                position: absolute;
                top: 0;
                z-index: 0;
            }
        }
        @include media-breakpoint-up(sm) {
            background-size: 1450px auto;
            background-position: right 40% top;
        }
        @include media-breakpoint-up(md) {
            background-size: 1550px auto;
            background-position: right 50% top;
        }
        @include media-breakpoint-up(lg) {
            background-size: 1700px auto;
            background-position: right 55% top;
        }
        @include media-breakpoint-up(xl) {
            background-position: center top;
            background-size: 2000px auto;
        }
        // &.empty {
        //     background-position: center -150px;
        // }
        .container {
            position: relative;
            z-index: 2;
            img.steps {
                max-width: 100%;
                @include media-breakpoint-up(sm) {
                    max-width: 400px;
                }
                @include media-breakpoint-up(lg) {
                    max-width: 600px;
                }
            }
        }
        @media (max-width: 1200px) {
            // background-size: 1300px auto;
            // background-position: center top;
            .container {
                padding: 180px 15px 40px 15px;
                h1 {
                    font-size: 33px;
                }
            }
        }
        @media (max-width: 768px) {
            // background-size: 750px auto;
            // background-image: url("/img/headers/header_bg_mobile_2.jpg");
            .container {
                padding: 156px 15px 40px 15px;
                h1 {
                    font-size: 25px;
                }
            }
        }
        @media (max-width: 643px) {
            .container {
                padding: 140px 15px 40px 15px;
                h1 {
                    margin-bottom: 0;
                }
            }
        }
    }
}
