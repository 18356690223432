@import "./components/colors";
$font-family-sans-serif: BridgestoneType, Helvetica, Arial, serif;
$container-max-widths: (
  sm: 100%,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-select/sass/bootstrap-select";
@import "./pages/start";
@import "./pages/warranty";
@import "./pages/locale_select";
@import "./global";
@import "./components/navbar";
@import "./components/header";
@import "./components/footer";
@import "./components/buttons";
@import "./components/dropdowns";
@import "./components/forms";
@import "./components/typo";
@import "./components/haendlersuche";

html {
    position: relative;
    min-height: 100%;
}
body {
    #content {
        padding-bottom: 80px;
        overflow-y: auto;
        min-height: 750px;
    }
}
