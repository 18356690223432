$form-field-font-size: 16px;

.backend_button {
    position: absolute;
    z-index: 9999;
}

img {
    max-width: 100%;
}

body {
    @include media-breakpoint-up(lg) {
        &::before {
            content: "";
            height: 80px;
            width: 100%;
            background: linear-gradient(to bottom, rgba($black, 0.5), transparent);
            position: absolute;
            top: 0;
        }
    }
}
