.bootstrap-select.form-control {
  width: 100%;
  background: none;
  border-radius: 0;
  height: 45px;

  &.disabled {
    pointer-events: none;
  }
  button.btn {
    height: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: white 0px -1px 0px 0px inset;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(3, 24, 36, 0.24) rgba(3, 24, 36, 0.24) rgb(92, 105, 113);
    border-image: initial;
    border-radius: 3px;
    margin-bottom: 0;
    padding-right: 5px;
    padding-top: 0;
    &:hover, &:active {
      background: white;
      color: $key-color;
    }
    .filter-option {
      margin-top: 0;
      font-family: BridgestoneType, Helvetica, Arial, serif;
      font-size: 14px;
      font-weight: 700;
      padding-top: 19px;
    }
    //Chevron
    &:after {
      border-top:inherit;
      border-right:inherit;
      border-bottom:inherit;
      border-left:inherit;
      margin-right: 10px;
      border-style: solid !important;
      border-width: 2px 2px 0 0 !important;
      border-color: black;
      content: '';
      display: inline-block;
      height: 0.45em;
      left: 0em;
      position: relative;
      top: 0em;
      transform: rotate(135deg);
      vertical-align: top;
      width: 0.45em;
    }
    &:focus {
      outline: none !important;
      // border: none;
    }
  }
  &:after {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 72px;
  }
  .dropdown-menu {
    border-radius: 0;
  }
  &.open .dropdown-menu.inner li {
    a:focus, a:hover {
      outline: none;
      background: transparent;
      font-weight: bold;
    }
    .text{
      height: 19px;
      margin-bottom: 0;
      background: transparent;
    }
  }
  .filter-option{
    font-size: $form-field-font-size - 1;
    font-weight: 900;
    color: #000;
  }
  .dropdown-item.active, .dropdown-item:active {
    background: lightgray;
    font-weight: bold;
  }
  .dropdown-menu li a {
    padding: 0.5rem 1.2rem;
  }
}
.isgray .bootstrap-select.btn-group .dropdown-toggle .bs-caret{
  background: #cecece;
}
