.nav-head {
    border-radius: 0;
    background-color: transparent;
    background: linear-gradient(107deg,white 50%, #ed1c24 50%
    );
    border: none;
    justify-content: center;
    // position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1000;
    // transition: background 0.3s cubic-bezier(0.28, 0.11, 0.32, 1) 0s, top 0.3s cubic-bezier(0.28, 0.11, 0.32, 1) 0s,
    //     box-shadow 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
    height: 56px;
    // color: white;
    // padding-top: 0;
    padding: 0;
    .logos {
        min-height: 47px;
        .bridgestone-logo .navbar-brand {
            padding-top: 7px;
        }
    }
    a.nav-link {
        padding-left: 1rem;
        color: #fff;
        @media (max-width: 991px) {
            color: black;
        }
    }
    #navbarSupportedContent {
        padding-top: 100px;
    }
    // &.scrolling__down {
    //     color: black;
    //     background-color: rgba(255, 255, 255, 0.9);
    //     top: 0;
    //     a.nav-link {
    //         @include media-breakpoint-up(lg) {
    //             padding-left: 1rem;
    //         }
    //     }
    //     .bridgestone-logo {
    //         opacity: 1;
    //     }
    //     .hotline-head {
    //         top: -13px;
    //         color: rgb(255, 255, 255);
    //         p {
    //             transition: 0.3s;
    //             opacity: 0;
    //             &.hotline-number {
    //                 opacity: 1;
    //             }
    //         }
    //     }
    //     .navbar-toggler {
    //         svg {
    //             fill: currentColor;
    //             color: #ed1c1a;
    //         }
    //     }
    // }
    .olympics-logo {
        display: none;
    }
    .bridgestone-logo {
        opacity: 1;
        position: absolute;
        top: 0;
        transition: opacity 0.2s ease-in-out 0s, transform 0.5s ease-in-out 0s, -webkit-transform 0.5s ease-in-out 0s;
    }
}
.navbar-brand {
    padding: 15px 0;
    margin-left: 5px !important;
}
.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
    max-width: 160px;
    width: 100%;
}
.lang-switcher {
    margin-top: 40px;
    display: inline-block;
    font-size: 20px;
    margin-left: 15px;
    a:hover {
        text-decoration: none;
    }
    .active {
        font-weight: bold;
    }
}
.bmark-olympic-header {
    img {
        max-width: 240px;
        margin: 20px auto 0;
        display: block;
    }
}

.navbar-collapse .nav-item.only-mobile,
.mobile-menu-header {
    display: none;
}

@media (min-width: 992px) {
    .nav-item {
        position: relative;
        > ul {
            left: 0;
            top: 100%;
            opacity: 0;
            width: 200px;
            max-height: 0;
            overflow: hidden;
            position: absolute;
            list-style-type: none;
            background-color: #fff;
            border: 1px solid #ccc;
            border-top: 0 none;
            padding: 10px 10px 6px 10px;
            transition: opacity .25s, max-height .25s;
        }

        &:hover {
            > ul {
                opacity: 1;
                max-height: 100px;
                transition: opacity .25s, max-height .25s;
            }
        }
    }
}

@media (max-width: 991px) {
    .navbar {
        .container {
            width: 100%;
        }
    }

    .nav-item {
        > ul {
            display: block;
            li {
                list-style-type: none;
                border-top: 1px solid rgba(3, 24, 36, 0.08);
                a {
                    position: relative;
                    &::after {
                        content: "\f054";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        position: absolute;
                        right: 15px;
                        top: 25px;
                    }
                }
            }
        }
    }

    .navbar-toggler {
        position: absolute;
        left: 5px;
        top: 8px;
        svg {
            fill: currentColor;
            color: #ed1c1a;
        }
        &:focus {
            outline: none;
        }
    }

    .navbar-collapse {
        width: 370px;
        background: white;
        color: black;
        height: 100vh;
        position: absolute;
        top: 0;
        left: -370px;
        transition: left ease-out 400ms;
        text-shadow: none;
        &:not(.show) {
            display: block;
        }
        &.show {
            left: 0;
        }
        .nav-item {
            border-bottom: 1px solid rgba(3, 24, 36, 0.08);
            position: relative;
            &:last-child {
                border-bottom: 0;
            }
            a,> span {
                padding: 25px;
                font-size: 16px;
                font-weight: 500;
            }
            &.only-mobile {
                display: block;
                a {
                    color: rgba(3, 24, 36, 0.64);
                }
            }
            &:after {
                content: "\f054";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                position: absolute;
                right: 15px;
                top: 25px;
            }
        }
    }
    .mobile-menu-header {
        display: block;
        position: relative;
        width: 100%;
        z-index: 100;
        background: whitesmoke;
        border-bottom: 1px solid rgba(3, 24, 36, 0.08);
        text-align: right;
        .close-menu {
            padding: 20px;
            border: 0;
            background: transparent;
            &:focus {
                outline: none;
            }
        }
        .fa-times {
            vertical-align: middle;
            margin-left: 5px;
        }
    }
    .navbar .logos {
        width: 100%;
        height: 50px;
        display: flex;
        padding-left: 50px;
    }
}
@media (max-width: 767px) {
}
