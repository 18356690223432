.partner {
  margin-top: 5rem;
  .haendler-ergebnisse {
    width: 100%;
    height: 500px;
    overflow-y: scroll;
  }
}

.haendler-ergebniss {
  position: relative;
  height: 100px;
  width: 100%;
  background: #fff;
  border: 1px solid #CCCCCC;
  font-size: 0.8em;
  margin: 13px 0 20px 0;
  padding: 15px 0 0 0px;
  color: $text-dark;
  cursor: pointer;
  > div {
    padding-left: 20px;
  }
  h5 {
    margin-top: 10px;
    margin-bottom: 0;
  }
  p {
    padding-top: 10px;
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 25px;
    white-space: pre;
    overflow-x: hidden;
  }
  &.active {
    background: $key-color;
    color: white;
    &::after {
      border-color: white;
    }
  }
  .btn {
    margin-top: 4px;
  }
  &::after {
    margin-right: 10px;
    border-style: solid !important;
    border-width: 2px 2px 0 0 !important;
    border-color: black;
    content: '';
    display: inline-block;
    right: 2px;
    position: absolute;
    top: 44px;
    transform: rotate(45deg);
    vertical-align: top;
    height: 10px;
    width: 10px;
  }
}

.widget--haendlersearch {
  background: $key-color;
  text-align: center;
  padding: 22px 20px;
  position: relative;
  h5 {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0px 0 15px 0;
  }
  input {
    border: none;
    width: 100%;
    &:focus {
      outline: none;
      border-radius: 0;
      border: none;
    }
  }
  input:focus + .haendler-ergebnisse {
    display: block;
  }
  .haendler-ergebnisse {
    cursor: pointer;
    position: absolute;
    border: 1px solid #CCC;
    background: white;
    left: 20px;
    right: 20px;
    max-height: 400px;
    overflow-y: scroll;
    display: none;
    z-index: 2;
    .ergebniss {
      text-align: left;
      p {
        padding: 5px;
        margin-bottom: 0;
        font-size: 13px;
      }
    }
  }
}

#haendlersuche-modal {
  .col-sm-3 {
    text-align: right;
  }
  .btn-print {
    height: 47px;
  }
}

@media print {
  .haendlersuche-modal-content-wrap {
    font-family: $font-family-sans-serif;
  }
  .inputtext-haendlersearchval {
    display: none;
  }
}

/* Materialbestellungen */
.materialselection{
  input{
    margin-right: 7px;
  }
}