@keyframes arrow-animation {
  0% {padding-left: 2px; padding-right: 18px; color: rgba(255,255,255,0.4);}
  60% {padding-left: 11px; padding-right: 9px; color: currentColor;}
  100% {padding-left: 20px; padding-right: 0px; color: rgba(255,255,255,0.4);}
}
.btn {
  text-transform: uppercase;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  border-radius: 0;
  padding: 7px 24px 5px;
  letter-spacing: 1.3px;
  max-width: none;
  border: none;
  background: $key-color;
  color: white;
  margin-bottom: 10px;

  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  flex-flow: row nowrap;
  &:active,
  &:hover,
  &:focus {
    background: $key-color-darker;
    color: white;
    outline: none;
    box-shadow: none;
  }
  &.btn-primary {
    background: $key-color;
  }
  &.btn-link {
    color: inherit;
    border: none;
    background: none;
    outline: none;
    margin-bottom: 0
  }

  &.btn-icon {
    padding-right: 10px;
  }
  &:hover .icon {
    animation-name: arrow-animation;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
  }
  
  // Button mit Text zentriert
  &.centered {
    text-align: center;
  } 
  
   // Button auf volle Breite
  &.btn-fullwidth {
    width: 100% !important;
  } // Icons in Buttons
  .icon {
    width: 13px;
    height: 12px;
    margin-top: -4px;
    fill: currentcolor;
    color: white;
    padding-left: 11px;
    box-sizing: content-box;
    padding-left: 11px; padding-right: 9px;
    &.plus {
      height: 12px;
      width: 12px;
    }
  } // Farben umgedreht
  &.btn-inverted {
    background: white;
    color: $key-color;
    &:active,
    &:hover {
      background: white;
      color: $key-color;
    }
    .icon {
      fill: currentcolor;
      color: $key-color;
    }
  } //Grauer Button - wenn inverted auf weiß ist, wahrscheinlich
  &.btn-gray {
    @extend .btn-inverted;
    background: #f2f2f2;
    &:active,
    &:hover {
      background: #f2f2f2;
    }
  } //Großer Button. Zu sehen auf /register
  &.btn-hero {
    width: 475px;
    height: 52px;
    padding-top: 16px;
    letter-spacing: normal;
    .icon {
      height: 14px;
      width: 14px;
      top: 18px;
    }
  }
  &.btn-icon-left {
    padding-left: 40px;
    .icon {
      left: 20px;
      top: 13px;
      transform: rotate(180deg);
    }
  } // Kleiner Button für tabellen
  &.btn-xs {
    font-size: 12px;
    padding: 5px 10px;
    font-weight: normal;
  } // Wenn buttons neben Input feldern gleich hoch aussehen sollen
  &.btn-form-control-height {
    padding: 16px 40px 14px 30px;
    height: 52px !important;
    font-size: 15px;
    .icon {
      top: 21px;
    }
  }
}

/* Aus der Sortierung */

a,
a:hover {
  color: inherit;
}

a:focus,
a:active,
a:visited,
a:link {
  outline: none;
  border: none;
}

.red {
  color: $key-color;
  fill: currentcolor;
}

b.red {
  letter-spacing: 1.3px;
}

.black {
  color: #232323;
}

.white {
  background: #ffffff;
}

.block2 .button-white:hover,
.block4 .button-white:hover {
  background: #f2f2f2;
}

.btn-backend {
  color: #fff !important;
}

a.disabled, .btn-primary.disabled, .btn-primary:disabled {
  color: #323232 !important;
  background: #838383 !important;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5 !important;
  pointer-events: none;
  .icon {
    color: #323232 !important;
  }
}

.cc_message {
  a.cc_more_info {
    color: $key-color;
    svg {
      height: 10px;
      width: 10px;
    }
  }
}