input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #d9d9d9 inset;
    transition: background-color 1s ease-in-out 0s;
}
#content label {
    font-weight: normal;
    a {
        text-decoration: underline;
    }
}
.bs-input {
    position: relative;
    font-size: 10px;
    label {
        -webkit-font-smoothing: antialiased;
        pointer-events: none;
        display: block;
        font-weight: 100;
        left: 0px;
        max-height: 1.8em;
        position: absolute;
        top: 0px;
        transform: translate(24px, 15px);
        transform-origin: left bottom;
        user-select: none;
        z-index: 2;
        transition: transform 150ms ease 0s, -webkit-transform 150ms ease 0s;
        margin-top: 0;
        margin-bottom: 0;
        color: black;
        font-size: 16px;
        line-height: 100%;
    }

    &.is-floating-label {
        label {
            .invalid-feedback {
                display: none !important;
            }
        }
    }
    input:focus + label,
    input.active + label,
    .dropdown.active + label,
    textarea:focus + label,
    textarea.active + label {
        transform: translate(24px, 3px) scale(0.7);
    }
    input:focus,
    input.active,
    textarea:focus,
    textarea.active {
        background: rgb(247, 247, 247);
        border-style: solid;
        border-color: rgba(3, 24, 36, 0.24) rgba(3, 24, 36, 0.24) rgb(92, 105, 113);
        border-image: initial;
        border-bottom: 1px solid rgb(92, 105, 113);
        border-radius: 3px;
        border-width: 1px;
        box-shadow: white 0px -1px 0px 0px inset;
    }
    input,
    textarea {
        min-height: 45px;
        background-color: transparent;
        box-sizing: border-box;
        color: rgb(255, 255, 255);
        display: inline-block;
        font-family: Avenir, Helvetica, Arial, serif;
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        border-image: initial;
        margin: 0px;
        outline: none;
        padding: 19px 5px 6px 24px;
        transition: border 250ms ease 0s, background-color 250ms ease 0s;
        background-color: rgb(255, 255, 255);
        box-shadow: white 0px -1px 0px 0px inset;
        color: rgb(3, 24, 36);
        border-style: solid;
        border-color: rgba(3, 24, 36, 0.24) rgba(3, 24, 36, 0.24) rgb(92, 105, 113);
        border-image: initial;
        border-bottom: 1px solid rgb(92, 105, 113);
        border-radius: 3px;
        border-width: 1px;
    }
    .invalid-feedback {
        // margin-top: -40px;
        font-size: 12px !important;
    }
}
.vich {
    position: relative;

    &.is-floating-label {
        legend {
            .invalid-feedback {
                display: none !important;
            }
        }
    }
    label {
        transform: none;
    }
    legend {
        -webkit-font-smoothing: antialiased;
        pointer-events: none;
        display: block;
        font-weight: 100;
        left: 0px;
        max-height: 1.8em;
        position: absolute;
        top: 0px;
        transform-origin: left bottom;
        user-select: none;
        z-index: 2;
        transition: transform 150ms ease 0s, -webkit-transform 150ms ease 0s;
        margin-top: 0;
        margin-bottom: 0;
        color: black;
        font-size: 16px;
        line-height: 100%;
        transform: translate(24px, 9px);
    }
    .vich-file:focus + legend,
    .vich-file.active + legend {
        transform: translate(24px, -5px) scale(0.7);
    }
    .custom-file {
        height: calc(1.5em + 0.75rem + 10px);
    }
    .custom-file-label {
        min-height: 45px;
        display: inline-block;
        font-family: Avenir, Helvetica, Arial, serif;
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        border-image: initial;
        margin: 0px;
        outline: none;
        padding: 19px 0 6px 22px;
        transition: border 250ms ease 0s, background-color 250ms ease 0s;
        background-color: rgb(255, 255, 255);
        box-shadow: white 0px -1px 0px 0px inset;
        color: rgb(3, 24, 36);
        border-style: solid;
        border-color: rgba(3, 24, 36, 0.24) rgba(3, 24, 36, 0.24) rgb(92, 105, 113);
        border-image: initial;
        border-bottom: 1px solid rgb(92, 105, 113);
        border-radius: 3px;
        border-width: 1px;
        &::after {
            height: 100%;
            content: "\f093";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            background: $key-color;
            color: white;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            padding: 10px 20px;
        }
    }
    .custom-file-input:focus ~ .custom-file-label {
        border-style: solid;
        border-color: rgba(3, 24, 36, 0.24) rgba(3, 24, 36, 0.24) rgb(92, 105, 113);
        border-image: initial;
        border-bottom: 1px solid rgb(92, 105, 113);
        box-shadow: none;
    }

    .invalid-feedback {
        font-size: 12px !important;
    }
}
/* Rechnungen hinzufügen */
#ui-datepicker-div {
    display: none;
    cursor: pointer;
    background: #fff;
    min-width: 310px;
    padding: 10px;
    margin: 2px 0 0;
    font-size: 16px;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    span {
        &.ui-icon.ui-icon-circle-triangle-w {
            color: $key-color;
            float: left;
            font-size: 26px;
        }
        &.ui-icon.ui-icon-circle-triangle-e {
            color: $key-color;
            float: right;
            font-size: 26px;
        }
    }
    .ui-datepicker-title {
        text-align: center;
        font-weight: bold;
        color: $key-color;
        padding-top: 10px;
        margin-bottom: 15px;
        font-size: 20px;
    }
    .ui-datepicker-calendar {
        width: 100%;
        tr td,
        th {
            text-align: center;
        }
    }
}
.ui-datepicker-trigger {
    background: $key-color;
    border: none;
    border-radius: 0;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    width: 72px;
    height: 52px;
    padding: 10px;
    cursor: pointer;
}
.ui-datepicker-today a {
    color: $key-color;
    font-weight: bold;
}
.ui-state-disabled {
    color: #acacac;
}
.ui-datepicker {
    z-index: 9 !important;
}