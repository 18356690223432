section.header .container.locale-select-head h1 {
    font-size: 34px;
}

.container.locale-select{
    padding-top: 40px;

    .row--country-flags {
        margin-top: 30px;
    }

    .langs_fr-ch {
        margin-top: 20px;
    }
    h1 {
        text-shadow: rgba(0, 0, 0, 0.4) 0 0 10px, rgba(0, 0, 0, 0.8) 0 0 5px;
    }
}