.warranty-list {

  .warranty-item {
    position: relative;
    min-height: 100px;
    width: 100%;
    background: #fff;
    border: 1px solid #CCCCCC;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    ul {
      margin-bottom: 0;
    }
  }
  //Direkt Link ohne Fahrzeug
  a.warranty-item {
    .icons {
      padding-right: 22px;
    }
  }
  .desc {
    padding: 25px 22px 10px 22px;
    width: 100%;
  }
  .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      border-radius: 3px;
      width: 18px;
      height: 18px;
      text-align: center;
      color: white;
      font-size: 13px;
      background-color: black;
    }
    .pending {
      background: $yellow;
    }
    .waiting {
      background: $key-color;
    }
    .done {
      background: $green;
      &.pending {
        background: lighten($green, 25);
      }
    }
    div {
      margin-right: 10px;
    }
  }
  .warranty-step {
    &:last-child {
      
    }
  }
}

.warranty {
  .icon {
    border-radius: 3px;
    width: 18px;
    height: 18px;
    text-align: center;
    color: white;
    font-size: 13px;
  }
  .waiting {
    background: $key-color;
  }
  .done {
    background: $green;
  }
}

.warranty-step {
  position: relative;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #CCCCCC !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 5px 0;
  padding: 20px 0;
  &:last-child {
    border-bottom: none !important;
  }
}