@font-face {
  font-family: 'BridgestoneType';
  src: url('/fonts/BridgestoneType/BridgestoneType-Regular.ttf') format('ttf'),
      url('/fonts/BridgestoneType/BridgestoneType-Regular.ttf') format('ttf'),
      url('/fonts/BridgestoneType/BridgestoneType-Regular.ttf') format('truetype'),
      url('/fonts/BridgestoneType/BridgestoneType-Regular.svg#BridgestoneType-Regular') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BridgestoneType';
  src: url('/fonts/BridgestoneType/BridgestoneType-Bold.ttf') format('ttf'),
      url('/fonts/BridgestoneType/BridgestoneType-Bold.ttf') format('ttf'),
      url('/fonts/BridgestoneType/BridgestoneType-Bold.ttf') format('truetype'),
      url('/fonts/BridgestoneType/BridgestoneType-Bold.svg#BridgestoneType-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BridgestoneType';
  src: url('/fonts/BridgestoneType/BridgestoneType-Medium.ttf') format('ttf'),
      url('/fonts/BridgestoneType/BridgestoneType-Medium.ttf') format('ttf'),
      url('/fonts/BridgestoneType/BridgestoneType-Medium.ttf') format('truetype'),
      url('/fonts/BridgestoneType/BridgestoneType-Medium.svg#BridgestoneType') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



h1, h2, h3 {
  font-weight: 800;
  text-transform: uppercase;
}
h1{
  font-size: 28px;
  letter-spacing: 1.4px;
  margin: 0 0 13px;
}
h2{
  font-size: 24px;
  letter-spacing: 4.5px;
  line-height: 36px;
  margin-top: 16px;
  &::before {
    content: "";
    display: block;
    position: absolute;
    margin-top: -16px;
    width: 16px;
    height: 3px;
    background-color: currentColor;
    border-radius: 0.2rem;
  }
}

h3 {
  font-size: 22px;
  letter-spacing: 4.5px;
  line-height: 36px;
  margin-top: 16px;
  &::before {
    content: "";
    display: block;
    position: absolute;
    margin-top: -16px;
    width: 16px;
    height: 3px;
    background-color: currentColor;
    border-radius: 0.2rem;
  }
}

h4 {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
}

label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 25px;
  display: block;
}
label.thin {
  font-weight: normal;
  margin-top: 52px;
  margin-left: 20px;
}
.checkbox label {
  margin-top: 0;
}
.pflichtfeld{
  margin-left: 10px;
  font-style: italic;
}
p {
  font-size: 16px;
  a {
    text-decoration: underline;
  }
}
.table {
  color: inherit;
}

.text-red {
  color: $key-color;
}

.alert a {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
}

// @media (max-width: 768px) {
//   h1 {
//     font-size: 35px;
//   }
// }